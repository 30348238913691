<script>
import { defineComponent } from 'vue'
import { useLocalization } from '/~/composables/localization'
import DefaultHelp from '/~/views/help/help.vue'

export default defineComponent({
  name: 'help',
  extends: DefaultHelp,
  setup() {
    const { translate } = useLocalization()

    return {
      translate,
    }
  },
})
</script>

<template>
  <div class="mx-auto mb-12 w-sm max-w-full px-5">
    <base-box :title="showThanks ? 'Your email has been sent!' : 'Contact us'">
      <template v-if="showThanks">
        Thank you for contacting us, we will be in touch shortly
        <div v-if="phone">
          <p>
            Or call
            <b>{{ phone }}</b>
            .
          </p>
          <p>
            Please note, calls and emails are managed 9.00am to 5.00pm AEST,
            Monday to Friday.
          </p>
        </div>
      </template>
      <template v-else>
        <base-placeholder v-if="isLoading" :rows="3" type="text" />
        <validation-observer
          v-else
          v-slot="{ handleSubmit }"
          ref="validationObserverRef"
          slim
        >
          <form @submit.prevent="handleSubmit(sendForm)">
            <base-input
              v-model="form.name"
              :validation="{
                rules: 'required',
                name: 'Name',
              }"
              :error="backendErrors.name"
              :disabled="isSubmitting"
              label="Name"
              name="name"
            />
            <base-input
              v-model="form.email"
              :validation="{
                rules: 'required|email',
                name: 'Email',
              }"
              :error="backendErrors.email"
              :disabled="isSubmitting"
              label="Email address"
              name="email"
              type="email"
            />
            <base-textarea
              v-model="form.message"
              :validation="{
                rules: 'required',
                name: 'Message',
              }"
              focused
              :error="backendErrors.message"
              :disabled="isSubmitting"
              :rows="3"
              label="Message"
              name="message"
            />
            <div class="mt-10 flex justify-end">
              <base-button
                :disabled="disableSubmit"
                :loading="isSubmitting"
                type="submit"
                full-width="mobile"
              >
                Send message
              </base-button>
            </div>

            <div class="mt-[30px]">
              <p>
                For lead, points or program {{ translate('common.enquiries') }},
                please email
              </p>
              <p>
                <a href="mailto:seekerrewards@seeker.auspost.com.au">
                  <b>seekerrewards@seeker.auspost.com.au</b>
                </a>
                .
              </p>
              <p class="mt-[15px]">
                For reward store or order enquiries, please complete this form
                or call
              </p>
              <p>
                <a :href="`tel:${phone}`">
                  <b>{{ phone }}</b>
                </a>
                .
              </p>
              <div
                v-if="customerServiceText"
                class="mt-[15px]"
                v-html="customerServiceText"
              />
              <p v-else class="mt-[15px]">
                Please note, calls and emails are managed 9.00am to 5.00pm AEST,
                Monday to Friday.
              </p>
            </div>
          </form>
        </validation-observer>
      </template>
    </base-box>
  </div>
</template>
